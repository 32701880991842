<template>
 <div class="w-full relative">
  <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
   <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
  </div>

  <div v-if="showElementList" class="pointer-events-none absolute inset-y-0 right-2 pl-3 flex items-center text-gray-400">
   <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
    <path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd" />
   </svg>
  </div>
  <div v-if="!showElementList" class="pointer-events-none absolute inset-y-0 right-2 pl-3 flex items-center text-gray-400">
   <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
    <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
   </svg>
  </div>
  <input
   @focus="showElementList = true"
   @blur="showElementList = false"
   @input="$emit('update:searchElement', $event.target.value)"
   list="quickList"
   v-model="searchElement"
   :class="[showElementList ? 'rounded-t-md' : 'rounded-md', 'w-full bg-white border border-gray-300 focus:border-gray-300 py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:ring-0 focus:outline-0 focus:text-gray-900 focus:placeholder-gray-400 sm:text-sm']"
   :placeholder="selected.length > 0 ? `${selected.length} éléments sélectionnés` : urlType === 'extension' ? $t('dashboards.searchExtension') : $t('dashboards.searchQueue')"
   type="search"
  />

  <transition
   enter-active-class="transition ease-out duration-100"
   enter-from-class="transform opacity-0 scale-95"
   enter-to-class="transform opacity-100 scale-100"
   leave-active-class="transition ease-in duration-75"
   leave-from-class="transform opacity-100 scale-100"
   leave-to-class="transform opacity-0 scale-95"
  >
   <div v-if="showClickableList || showElementList" @mouseover="showClickableList = true" @mouseleave="showClickableList = false" class="absolute z-20 bg-white w-full border search_element_list">
    <div>
     <ul role="list" class="divide-y divide-gray-200">
      <li
       v-for="element in searchData"
       :key="element.label"
       :class="[element.isConstructor ? 'font-bold' : '', selected.indexOf(element.value) >= 0 || selected.indexOf(element.label) >= 0 || (groupSelected && groupSelectedName.indexOf(element.label) >= 0) ? 'bg-green-300' : '', 'py-1 cursor-pointer hover:bg-green-300 text-gray-700 text-xs']"
       @click="addElement(element)"
       v-show="searchElement.length > 0 ? element.label.toLowerCase().includes(searchElement.toLowerCase()) : true"
      >
       <p>{{ element.label }}</p>
      </li>
      <div v-if="searchElement.length > 0 && !slicedListIncludes()">
       <li
        v-for="element in elementListConcatReduce"
        :key="element.label"
        :class="[element.isConstructor ? 'font-bold' : '', selected.indexOf(element.value) >= 0 ? 'bg-green-300' : '', 'py-1 cursor-pointer hover:bg-green-300 text-gray-700 text-xs']"
        @click="addElement(element)"
        v-show="searchElement.length > 0 ? element.label.includes(searchElement) : true"
       >
        <p>{{ element.label }}</p>
       </li>
      </div>
      <infinite-loading @infinite="infiniteHandler">
       <template v-slot:no-more>
        {{ $t("noMoreData") }}
       </template>
      </infinite-loading>
     </ul>
    </div>
   </div>
  </transition>
 </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import { SearchIcon } from "@heroicons/vue/outline";
import { mapGetters } from "vuex";

export default {
 props: ["searchData", "infiniteHandler", "selected", "urlType", "addElement", "slicedListIncludes", "elementListConcatReduce"],
 components: {
  InfiniteLoading,
  SearchIcon,
 },
 data() {
  return {
   searchElement: "",
   showElementList: false,
   showClickableList: false,
  };
 },
 computed: {
  ...mapGetters(["groupSelected", "groupSelectedName", "pbxElementType"]),
 },
 watch: {
  urlType: function (val) {
   //  console.log("file: InputSearchSelector.vue:164 ~ val:", val);
  },
 },
 mounted() {
  if (this.pbxElementType !== this.urlType) {
   console.log("file: InputSearchSelector.vue:99 ~ mounted ~ this.urlType:", this.urlType);
   console.log("file: InputSearchSelector.vue:99 ~ mounted ~ this.pbxElementType:", this.pbxElementType);
   this.$store.dispatch("pbxElements", null);
   this.searchElement = "";
  }
 },
};
</script>

<style scoped>
.search_element_list {
 max-height: 15rem;
 overflow-y: auto;
}
</style>
